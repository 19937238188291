<template>
    <div>
        <nav class="absolute top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 py-3 navbar-expand-lg">
            <div class="container flex flex-wrap items-center justify-between px-4 mx-auto">
                <div class="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
                    <NuxtLink
                        class="inline-block py-2 mr-4 text-sm font-bold leading-relaxed text-white uppercase whitespace-nowrap"
                        href="/">
                        CMRU: LOCAL RESOURCE
                    </NuxtLink>
                    <button
                        class="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
                        type="button">
                        <i class="text-white fas fa-bars"></i>
                    </button>
                </div>
                <div class="items-center flex-grow hidden bg-white lg:flex lg:bg-opacity-0 lg:shadow-none"
                    id="example-navbar-warning">
                    <ul class="flex flex-col mr-auto list-none lg:flex-row">
                        <li class="flex items-center">
                            <NuxtLink
                                class="flex items-center px-3 py-4 text-xs font-bold text-gray-700 uppercase lg:text-white lg:hover:text-gray-200 lg:py-2"
                                href="https://bit.ly/3UNUi7p" target="_blank">
                                <i class="mr-2 text-lg text-gray-400 lg:text-gray-200 far fa-file-alt leading-lg"></i>
                                คู่มือการใช้งาน
                            </NuxtLink>
                        </li>
                    </ul>
                    <ul class="flex flex-col list-none lg:flex-row lg:ml-auto">
                        <li class="flex items-center">
                            <NuxtLink
                                class="flex items-center px-3 py-4 text-xs font-bold text-gray-700 uppercase lg:text-white lg:hover:text-gray-200 lg:py-2"
                                href="/" target="_blank">
                                <i class="text-lg text-gray-400 lg:text-gray-200 fab fa-twitter leading-lg "></i>
                                <span class="inline-block ml-2 lg:hidden">Tweet</span>
                            </NuxtLink>
                        </li>
                        <li class="flex items-center">
                            <NuxtLink
                                class="flex items-center px-3 py-4 text-xs font-bold text-gray-700 uppercase lg:text-white lg:hover:text-gray-200 lg:py-2"
                                href="#" target="_blank">
                                <i class="text-lg text-gray-400 lg:text-gray-200 fab fa-github leading-lg "></i>
                                <span class="inline-block ml-2 lg:hidden">Star</span>
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>