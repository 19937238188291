<template>
    <div class="relative">
        <footer class="absolute bottom-0 w-full bg-gray-800">
            <div class="container px-4 mx-auto">
                <div class="flex flex-wrap items-center justify-center md:justify-between md:h-14 py-4 sticky">
                    <div class="px-4">
                        <div class="py-1 text-sm font-semibold text-center text-gray-200 md:text-left mb-5 md:mb-0">
                            สงวนสิทธิ์ © 2023
                            <NuxtLink href="/" class="py-1 text-sm font-semibold text-white hover:text-gray-300">
                                มหาวิทยาลัยราชภัฏเชียงใหม่
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="px-4">
                        <ul class="flex flex-wrap justify-center list-none md:justify-end items-center">
                            <li>
                                <NuxtLink href="https://mhsc.cmru.ac.th/" target="_blank"
                                    class="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-300">
                                    วิทยาเขตแม่ฮ่องสอน
                                </NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/about"
                                    class="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-300">
                                    เกี่ยวกับระบบ
                                </NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="https://mhsc.cmru.ac.th/contact/" target="_blank"
                                    class="block px-3 py-1 text-sm font-semibold text-white hover:text-gray-300">
                                    ติดต่อเรา
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>